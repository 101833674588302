import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { Section, SectionWrapper, SubTitle } from "../core/commonExports"

import Star from "../../assets/images/star-gold.svg"

const cameraInfoItems = [
  {
    title: "Trusted manufacturing:",
    text: "Proudly made in India and released in July 2022"
  },
  {
    title: "Superior Quality:",
    text: "Captures footage in 1080p"
  },
  {
    title: "New-age features:",
    text: (
      <ul style={{listStylePosition: "inside"}}>
        <li>Cloud storage and backup</li>
        <li>Built-in alarm</li>
        <li>View footage in the app</li>
        <li>Attached speakers for real-time communication</li>
      </ul>
    )
  }
]

const SafetyPlanCamera = ({background="#FFF7F1"}) => {
  return (
    <Section background={background}>
      <SectionWrapper>
        <SubTitle fontSize="36px" mfontSize="20px" lineHeight="43px" mlineHeight="32px" fontWeight="bold" desktopStyles={{textAlign: "center"}} mobileStyles={{textAlign: "left"}}>
          Our CCTV cameras will keep your business safe!
        </SubTitle>
        <CameraContainer background={background}>
          <CameraImgContainer>
            <StaticImage 
              src="../../assets/images/safety-plan-camera.png"
              width={272}
              height={200}
              loading="lazy"
            />
          </CameraImgContainer>
          <CameraInfo>
            {cameraInfoItems.map(infoItem => (
              <CameraInfoItem>
                <Star style={{flexShrink: "0"}} />
                <div>
                  <strong>{infoItem.title}</strong> <br />
                  {infoItem.text}
                </div>
              </CameraInfoItem>
            ))}
          </CameraInfo>
        </CameraContainer>
      </SectionWrapper>
    </Section>
  )
}

export default SafetyPlanCamera

const CameraInfo = styled.ul`
  list-style-type: none;
`

const CameraInfoItem = styled.li`
  display: flex;
  gap: 22px;
`

const CameraImgContainer = styled.div`
  width: min(272px, 100%);
`

const CameraContainer = styled.div`
  background: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 24px;
  margin: auto;
  margin-top: 46px;
  max-width: 780px;
  border-radius: 12px;

  @media screen and (max-width: 768px) {
    background: ${p => p.background || "#FFF7F1"};
    margin-top: 36px;
    flex-direction: column;
    ul, li {
      text-align: left;
    }
  }
`