import React from "react";
import styled from "styled-components";
import loadable from "@loadable/component"
import { StaticImage } from "gatsby-plugin-image";
import {
  Section,
  SectionWrapper,
} from "../../components/core/commonExports";
import { useMixpanel } from "../../context/MixpanelContext";
import { getQueryParams } from "../../utils/queryParamUtils";
import { MetaComponent } from "../../components/MetaComponent";
import GLPNavbar from "../../components/campaigns/GLPNavbar";
import { getPageName } from "../../utils/getCurrentPageName";
import ApplyInterFont from "../../components/campaigns/ApplyInterFont";
import { OnDesktop } from "../../components/DesktopMobileComps";
import { Button } from "../../components/core/Button";
import HeroSection from "../../components/campaigns/HeroSection";
import { HeroBg, HeroImgContainer, HeroSectionContainer, HeroSectionData, HeroSectionHeading, ModalContainer, QuoteModalPlaceholder } from "../../components/campaigns/SharedComponents";
import { LeadFormProvider } from "../../context/LeadFormContext";
import SafetyPlanCamera from "../../components/safety-plan/SafetyPlanCamera";

const LeadForm = loadable(() => import("../../components/LeadForm"));
const Footer = loadable(() => import("../../components/campaigns/Footer"), {
  resolveComponent: (components) => components.Footer,
});

const SafeguardYourBusiness = loadable(() => import("../../components/campaigns/fbsft1/SafeguardYourBusiness"))
const WhySafetyInsurance = loadable(() => import("../../components/campaigns/fbsft1/WhySafetyInsurance"))
const FactsSection = loadable(() => import("../../components/campaigns/glp6/FactsSection"))
const ReviewsSection = loadable(() => import("../../components/campaigns/glp9/ReviewsSection"))
const WhyChooseVerak = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/glp6/WhyChooseVerak"))
const FooterSection = loadable(() => import("../../components/campaigns/fbsft1/FooterSection"))
const FAQs = loadable(() => import("../../components/campaigns/fbsft1/FAQs"))

const quoteCTAProps = {
  label: "GET PLANS"
}

const IndexPage = () => {
  const mixpanel = useMixpanel();
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);
  const [hideQuoteCTAs, setHideQuoteCTAs] = React.useState(false)

  // hero section states
  const [screen, setScreen] = React.useState("hero-image")
  
  // capture and store the query params beforehand
  getQueryParams();

  const pageName = getPageName()

  const handleQuoteModalOpen = () => {
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  const handleGetQuoteClick = (e, ctaNumber ="", ctaName = "GET PLANS") => {
    let eventName = `Clicked '${ctaName}' -`
    if (ctaNumber) {
      eventName += ` ${ctaNumber}`
    }
    eventName += ` (${pageName})`
    mixpanel.track(eventName);
    handleQuoteModalOpen();
  };

  React.useEffect(() => {
    document.addEventListener('touchstart', () => {}, {passive: true});
  }, [])

  const heroSectionDefaultComp = (
    <>
      <StyledHeroSectionHeading>
        Complete safety for your business starting at <span style={{color: "rgb(255, 147, 79)"}}> ₹8 per day!</span>
      </StyledHeroSectionHeading>
      <StyledHeroImgContainer>
        <StaticImage 
          src="../../assets/images/campaigns/fbsft1-hero.webp"
          alt="Complete safety for your business starting at ₹8 per day!"
          width={522}
          height={310}
          loading="eager"
          placeholder="none"
        />
      </StyledHeroImgContainer>
      {!hideQuoteCTAs && <OnDesktop><Button type="flatdesign" style={{padding: "12px 20px"}} label="GET PLANS" onClick={(e, ctaNumber=1) => handleGetQuoteClick(e, ctaNumber)} /></OnDesktop>}
    </>
  )

  return (
    <>
      <ApplyInterFont />
      <LeadFormProvider>
        {isQuoteModalOpen ? <LeadForm newTheme formType="safety-plan" quoteCtaProps={quoteCTAProps} onModalClose={handleQuoteModalClose} setHideQuoteCTAs={setHideQuoteCTAs} /> : null}
      </LeadFormProvider>
      <MetaComponent 
        title="Get complete protection for your business today!" 
        description="Complete safety for your business starting at ₹8 per day!" 
        image="mlp-4"
        loadYellowAiScript={false} 
        loadFbPixelScript={false} 
      />
      <GLPNavbar quoteCtaProps={quoteCTAProps} hideQuoteCTA={hideQuoteCTAs} newTheme handleGetQuoteClick={handleQuoteModalOpen} />
      <Section background="#ffffff" style={{marginTop: '4rem', position: "relative"}}>
        <StyledHeroBg />
        <SectionWrapper mobileStyles={{padding: "24px 0", paddingTop: "0px"}} desktopStyles={{paddingTop: "50px"}}>
          <LeadFormProvider>
            <HeroSectionData>
              <StyledHeroSectionContainer>
                <HeroSection 
                  screen={screen} 
                  setScreen={setScreen}
                  heroSectionDefaultComp={heroSectionDefaultComp}
                />
              </StyledHeroSectionContainer>
              <StyledModalContainer>
                <LeadForm 
                  newTheme 
                  formType="safety-plan" 
                  quoteCtaProps={quoteCTAProps}
                  renderAsPopup={false} 
                  setHideQuoteCTAs={setHideQuoteCTAs} 
                  style={{width: 'auto'}} 
                  fallback={<QuoteModalPlaceholder />}
                  setHeroSectionScreen={setScreen} 
                />
              </StyledModalContainer>
            </HeroSectionData>
          </LeadFormProvider>
        </SectionWrapper>
      </Section>
      <SafeguardYourBusiness showGetQuote quoteCtaProps={quoteCTAProps} handleGetQuoteClick={(e, ctaNumber=2) => handleGetQuoteClick(e, ctaNumber)}  />
      <FactsSection title="Why Trust Verak?" mobileStyles={{background: "#FFF"}} desktopStyles={{background: "#fff"}} />
      <WhySafetyInsurance showGetQuote quoteCtaProps={quoteCTAProps} handleGetQuoteClick={(e, ctaNumber=3) => handleGetQuoteClick(e, ctaNumber)}  />
      <SafetyPlanCamera background="#F6F9FF" />
      <ReviewsSection 
        quoteCtaProps={{label: "GET OFFER"}}
        mobileStyles={{background: "#F6F9FF"}} 
        desktopStyles={{background: "#fff"}} 
        handleGetQuoteClick={(e, ctaName="GET OFFER") => handleGetQuoteClick(e, "", ctaName)} 
      />
      <WhyChooseVerak scrollableCards quoteCtaProps={quoteCTAProps} showGetQuote handleGetQuoteClick={(e, ctaNumber=4) => handleGetQuoteClick(e, ctaNumber)} />
      <FooterSection 
        quoteCtaProps={{label: "CONTACT US"}}
        pageName={pageName}
        mobileStyles={{background: "#FFF"}}
        desktopStyles={{background: "#fff"}}
        handleGetQuoteClick={(e, ctaName="CONTACT US") => handleGetQuoteClick(e, "", ctaName)} 
      />
      <FAQs />
      <Footer style={{background: "#fff"}} />
    </>
  );
};

const StyledHeroBg = styled(HeroBg)`
  background: rgba(233, 239, 254, 0.4);
`

const StyledHeroSectionContainer = styled(HeroSectionContainer)`
  .limited-offer {
    position: absolute;
    left: -24px;
  }
  @media (max-width: 768px) {
    display: block;
    .limited-offer {
      left: -40px;
      width: 80px;
      height: 80px;
    }
  }
`

const StyledHeroImgContainer = styled(HeroImgContainer)`
  width: min(522px, 100%);
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  @media screen and (max-width: 768px) {
    width: min(254px, 100%);
    margin: auto;
    margin-top: 0.4rem;
    margin-bottom: 0;
  }
`

const StyledModalContainer = styled(ModalContainer)`
  width: min(520px, 100%);
`

const StyledHeroSectionHeading = styled(HeroSectionHeading)`
  font-family: Inter;
  font-size: 30px;
  line-height: 60px;
  font-weight: 700;
  flex: 1;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
`;

export default IndexPage;
